<template>
	<div>
		<div class="font-weight-bold asterisk-div">日程管理</div>
		<div style="font-size: 12px; color: #999999">
			点击新增日程增加日程条，点击日程条进行编辑，拖拽日程条可调整日程排序
		</div>
		<draggable :list="dets_list">
			<div v-for="(item, index) in dets_list" :key="index" @click="openDialog(item, index)" style="position: relative">
				<div style="position: absolute; top: -10px; right: -5px; z-index: 99999" @click.stop="remove(index)">
					<v-icon style="cursor: pointer" size="32" class="iconfont">
						iconfont icon-guanbi-fill
					</v-icon>
				</div>
				<div class="d-title d-flex justify-space-between">
					<div>
						{{ item.title }}
					</div>
					<div @click="item.show = !item.show">
						<v-icon style="transform: scale(0.6); cursor: pointer" size="24" class="iconfont">
							iconfont icon-zhankai
						</v-icon>
					</div>
				</div>
			</div>
		</draggable>
		<v-btn color="primary" block class="mt-5" @click="openDialog(null)">
			<v-icon size="13" class="iconfont">iconfont icon-zengjia</v-icon>
			<span class="ml-1">新增日程</span>
		</v-btn>
		<template v-if="dialog">
			<form-menu
					ref="formRef"
					:key="formKey"
					:listDeploy="listDeploy"
					:dialog.sync="dialog"
					title="编辑日程"
					@validateSave="save"/>
		</template>
	</div>
</template>

<script>
import formMenu from "@/components/form-menu/form-menu.vue";
import planningBox from "./planning-box.vue";
import draggable from 'vuedraggable';

export default {
	name: "schedule-manage",
	components: {
		formMenu,
		draggable
	},
	data() {
		return {
			select_index: null,
			formKey: 0,
			dialog: false,
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'title',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								required: true,
								label: "日程标题"
							}
						}
					},
					{
						cols: 12,
						subassembly: 'div',
						connector: {
							class: 'form-row-title asterisk-div',
							domProps: {
								innerHTML: '行程规划'
							}
						}
					},
					{
						cols: 7,
						subassembly: 'div',
						connector: {
							class: 'desc-text',
							domProps: {
								innerHTML: '点击添加行程新增行程条，点击行程条左侧区域进行编辑标题，点击行程条右侧区域展开查看内容' +
										'展开内容点击添加按钮新增上传区域，拖动选中的文件区域可对其排序，' +
										'图片建议：宽高比例16:9，大小不能超过2MB，格式仅限于jpg、png、jpeg、webp，数量上限为6张'
							}
						}
					},
					{
						cols: 12,
						subassembly: planningBox,
						property: 'dets',
						connector: {
							props: {}
						}
					}
				]
			},
			dets_list: []
		}
	},
	created() {
		this.dets_list = this.$parent.$parent.form.tips || []
	},
	methods: {
		save(v) {
			if (v.done) {
				this.$emit('change', v.form)
				if (this.select_index !== null) {
					this.dets_list.splice(this.select_index, 1, v.form)
				} else {
					this.dets_list.push(v.form)
				}
				this.$parent.$parent.form.tips = this.dets_list
				this.dialog = false
			}
		},
		openDialog(item, index) {
			if (item) {
				this.select_index = index
				this.listDeploy.row[0].default = item.title
				this.listDeploy.row[3].connector.props.items = item.dets
			} else {
				this.select_index = null
				this.listDeploy.row[0].default = ''
				this.listDeploy.row[3].connector.props.items = []
			}
			this.dialog = true
		},
		remove(index) {
			this.confirm.showConfirm('确定删除日程吗').then(() => {
				this.dets_list.splice(index, 1)
				this.$parent.$parent.form.tips = this.dets_list
			}).catch(() => {
			});
		},
	}
}
</script>

<style scoped lang="scss">
.d-title {
	border: 1px solid #999999;
	border-radius: 4px;
	padding: 8px;
	margin-top: 15px;
}
</style>

<template>
	<div>
		<div class="mt-5">
			<draggable :list="list">
				<div v-for="(item, index) in list" :key="index" style="position: relative" class="mb-4">
					<div style="position: absolute; top: -10px; right: -5px; z-index: 999" @click="remove(index)">
						<v-icon style="cursor: pointer" size="32" class="iconfont">
							iconfont icon-guanbi-fill
						</v-icon>
					</div>
					<v-text-field
							solo
							hide-details
							v-model="item.title"
							@click:append="item.show = !item.show; $forceUpdate()"
							placeholder="行程标题"
							append-icon="mdi-chevron-down"
					></v-text-field>
					<div class="d-content" v-show="item.show">
						<w-autocomplete :value="item.sitecode" placeholder="请选择标注" hide-details :items="select_list" @change="item.sitecode = $event"/>
						<material
								title="" @change="item.picurls = $event"
								:value="Array.isArray(item.picurls) ? item.picurls.join(','): item.picurls"/>
						<div class="mt-4">
							<editor-text @change="item.richtext = $event" :value="item.richtext"/>
						</div>
					</div>
				</div>
			</draggable>
		</div>
		<v-btn color="primary" block class="mt-5" @click="add">
			<v-icon size="13" class="iconfont">iconfont icon-zengjia</v-icon>
			<span class="ml-1">新增行程</span>
		</v-btn>
	</div>
</template>

<script>
import material from "@/components/common-material/material.vue";
import editorText from '@/components/editorText/editorText.vue';
import wAutocomplete from '@/components/w-autocomplete/w-autocomplete.vue';
import draggable from 'vuedraggable';
export default {
	name: "planning-box",
	components: {
		material,
		editorText,
		wAutocomplete,
		draggable
	},
	props: {
		items: Array
	},
	data() {
		return {
			select_list: [],
			list: [{picurls: '', richtext: '', title: '', show: false}]
		}
	},
	watch: {
		items: {
			handler(v) {
				if (v && v.length > 0) {
					this.list = v.map(item => {
						item.show = false
						return item
					})
				}
			},
			immediate: true
		},
		list: {
			handler(v) {
				this.$emit('change', v)
			},
			immediate: true,
			deep: true
		}
	},
	created() {
		this.axios.post(this.select_data, {keyname: 'parksite'}).then(res => {
			this.select_list = res.data[0].values
		})
	},
	methods: {
		remove(index) {
			this.confirm.showConfirm('确定删除行程吗').then(() => {
				this.list.splice(index, 1)
			}).catch(() => {
			});
		},
		add() {
			this.list.push({picurls: '', richtext: '', title: '', show: false, sitecode: ''})
		}
	}
}
</script>

<style scoped lang="scss">
.d-title {
	height: 40px;
	background: #FFFFFF;
	border-radius: 4px 4px 4px 4px;
	border: 1px solid #D8D8D8;
	display: flex;
	align-items: center;
	padding-left: 24px;
}

.d-content {
	background: #f5f5f5;
	padding: 16px 16px 0 16px;
}

::v-deep {
	.v-input__slot {
		box-shadow: none !important;
		border: 1px solid #e7e7e7;
		font-size: 14px;
		border-radius: 6px;
	}

	.v-select__slot {
		background: #FFFFFF;
		padding-left: 10px;
		font-size: 14px;
	}
}
</style>

<template>
	<div>
		<common-table
				:itemScope="['lshow']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				@search="search"
				@remove="remove"
				@save="save"
				@edit="edit"
				ref="ct"
				:rightConfig="rightConfig"
				:leftConfig="leftConfig"
				:listDeploy="listDeploy"
				pageTitle="路线"
		>
			<template v-slot:lshow="{ item }">
				<w-switches
						inset
						label=""
						color="var(--v-primary-base)"
						@change="updateStatus(item.sqlid)"
						v-model="item.lshow" hide-details dense/>
			</template>
		</common-table>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/productManage/route-planning.js';
import scheduleManage from "./schedule-manage.vue";

export default {
	mixins: [commonCurd],
	data() {
		return {
			api,
			// 排序字段，不填默认sqlid
			sortname: 'seq',
			// 定义右侧按钮
			rightConfig: [
				{label: '新建', icon: 'zengjia', event: 'add'}
			],
			// 定义左侧搜索框
			leftConfig: [
				{type: 'input', label: '输入路线代码/名称', property: 'searchkey', width: '200px'}
			],
			// 表格头部字段
			headers: [
				{text: '路线代码', value: 'code'},
				{text: '路线名称', value: 'description'},
				{text: '排序号', value: 'seq'},
				{text: '状态', value: 'lshow'},
				{text: '操作', value: 'actions', width: 120}
			],
			// 表单字段
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'code',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								required: true,
								label: "路线代码"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'description',
						connector: {
							props: {
								required: true,
								label: "路线名称"
							}
						}
					},
					// {
					// 	cols: 6,
					// 	subassembly: "WAutocomplete",
					// 	property: 'businesstype',
					// 	connector: {
					// 		props: {
					// 			required: true,
					// 			label: "酒店管理",
					// 			arrayType: false,
					// 			multiple: true,
					// 			items: []
					// 		}
					// 	}
					// },
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'seq',
						connector: {
							props: {
								label: "排序号码",
								type: 'number'
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'tags',
						connector: {
							props: {
								required: false,
								label: "出行方式",
								items: []
							}
						}
					},
					{
						cols: 6,
						subassembly: "WSwitches",
						property: 'lshow',
						disabledDeleteDefault: true,
						connector: {
							props: {
								label: '状态',
								inset: true,
								dense: true
							}
						},
						default: true
					},
					{
						cols: 12,
						subassembly: 'MaterialFile',
						property: 'slidepics',
						connector: {
							props: {
								subTitle: '点击添加按钮新增上传区域，拖动选中的文件区域可对其排序，' +
										'图片建议：宽高比例4:3，大小不能超过2MB，格式仅限于jpg、png、jpeg、webp，数量上限为6张',
								label: '上传图片',
								title: '应用端端轮播图'
							}
						}
					},
					{
						cols: 12,
						subassembly: 'div',
						connector: {
							class: 'form-row-title',
							domProps: {
								innerHTML: '标注简介'
							}
						}
					},
					{
						cols: 12,
						subassembly: 'VTextarea',
						property: 'introduction',
						connector: {
							props: {
								solo: true,
								rows: 2,
								outlined: true,
								flat: true
							}
						}
					},
					{
						cols: 12,
						subassembly: scheduleManage,
						property: 'tips'
					},
				]
			}
		}
	},
	methods: {

	},
	created() {
		this.axios.post(this.select_data, {keyname: 'tvltags'}).then(res=> {
			this.listDeploy.row[3].connector.props.items = res.data[0].values
		})
	}
}
</script>
